var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md pt-4"},[_c('FilterBar',{attrs:{"search-query":_vm.consentCollectionPreferencePageSearchQuery,"search-query-label":"Preference Page Name","selected-brand-id":_vm.selectedAdminPortalBrandId},on:{"update:searchQuery":function($event){_vm.consentCollectionPreferencePageSearchQuery=$event},"update:search-query":function($event){_vm.consentCollectionPreferencePageSearchQuery=$event},"update:selectedBrandId":function($event){_vm.selectedAdminPortalBrandId=$event},"update:selected-brand-id":function($event){_vm.selectedAdminPortalBrandId=$event},"persistSearchQuery":_vm.changeConsentCollectionPreferencePageSearchQuery,"persistSelectedBrandId":_vm.changeAdminPortalBrandFilter,"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('Dropdown',{attrs:{"items":_vm.authTypeFilter,"custom-sort":"","label":"Authentication Type"},on:{"input":function($event){return _vm.changeConsentCollectionPreferencePageAuthType($event)}},model:{value:(_vm.consentCollectionPreferencePageAuthType),callback:function ($$v) {_vm.consentCollectionPreferencePageAuthType=$$v},expression:"consentCollectionPreferencePageAuthType"}}),_c('Dropdown',{attrs:{"items":_vm.statusFilterItems,"custom-sort":"","label":"Status"},on:{"input":function($event){return _vm.changeConsentCollectionPreferencePageStatusType($event)}},model:{value:(_vm.consentCollectionPreferencePageStatusType),callback:function ($$v) {_vm.consentCollectionPreferencePageStatusType=$$v},expression:"consentCollectionPreferencePageStatusType"}})]},proxy:true},{key:"action",fn:function(){return [(_vm.userFullPermissions)?_c('PrimaryActionButton',{on:{"click":function($event){return _vm.$router.push({
					name: _vm.MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
					params: {
						userFullPermissions: _vm.userFullPermissions
					}
				})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preference Pages ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"sort-desc":"","sort-by":"id","headers":_vm.tableHeaders,"items":_vm.filteredPreferencePages},on:{"click:row":_vm.onEditClick},scopedSlots:_vm._u([{key:"item.preferencePageName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.preferencePageName)+" "),(item.status !== _vm.COMPLETE_STATUS)?_c('v-chip',{staticClass:"ml-2 aqua-blue white--text",attrs:{"x-small":""}},[_vm._v(" In Progress ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
				var item = ref.item;
return [(_vm.userFullPermissions && _vm.userHasBrand(item.brandId))?_c('IconButton',{attrs:{"tooltip-text":"Edit Preference Page"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-pencil ")]):_c('IconButton',{attrs:{"tooltip-text":"View Preference Page"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-eye ")]),(item.status === 5)?_c('IconButton',{attrs:{"tooltip-text":"View Embed Code"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onViewEmbedClick(item)}}},[_vm._v(" mdi-link ")]):_vm._e(),(_vm.shouldShowPreview(item) && item.status === 5)?_c('IconButton',{attrs:{"tooltip-text":"Preview link"},on:{"click":function($event){$event.stopPropagation();return _vm.onPreviewClick(item)}}},[_vm._v(" mdi-open-in-app ")]):_vm._e(),(_vm.userFullPermissions && _vm.userHasBrand(item.brandId))?_c('IconButton',{attrs:{"tooltip-text":"Delete Preference Link"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.preferencePageToRemove = item}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.preferencePageToRemove)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.preferencePageToRemove.name,"entity-type":"Preference Page"},on:{"close":function($event){_vm.preferencePageToRemove = null},"delete":_vm.deletePreferencePage}}):_vm._e(),(_vm.prefPageToView)?_c('ViewPrefPageLinkModal',_vm._b({attrs:{"assigned-language-items":_vm.assignedLanguagesItems},on:{"close":function($event){_vm.prefPageToView = null}}},'ViewPrefPageLinkModal',_vm.prefPageToView,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }